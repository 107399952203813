export const environment = {
  production: true,
  isDemo: false,
  useBuildingNumberAsBoardKey: true,
  singleBuildingModeBoardKey: null,
  portegoWebserviceUrl: 'https://portego.schwarzesbrett.bfm-facility.at/api/',
  openWeatherMapApiKey: 'e7f0fd2faef4b9228cc30e108da1762f',
  htmlDocumentTitle: 'Schwarzes Brett',
  hideCompanyLogo: false,
  links: [
    [
      {
        url: 'https://bodner-immobilien.at',
        iconPath: 'assets/images/link_logo.svg',
        title: 'www.bodner-immobilien.at'
      },
    ]
  ]
};
